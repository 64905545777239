import { MainHeader } from '../../Components/Header/MainHeader';
import { Navbar } from '../../Components/Navigation/Navbar';
import LinkedIcon from '../../utils/icons/LinkedIcon';
import { MailIcon } from '../../utils/icons/iconsConfig';

import './contact.css';


export const Contact = () => {
    return(
        <div className='contact-container'>
            <MainHeader text='LETS WORK TOGETHER'/>
            <div className='contact-content'>
                <p>I am available for inquiries or career opportunities, and welcome any communication regarding professional queries or potential collaborations.</p>
            </div>
            <div className='contacts'>
                <a className='linked-icon' href='https://www.linkedin.com/in/onni-vitikainen-a9a57a16b/' target='_blank' rel='noreferrer'>
                    <LinkedIcon />
                </a>
                <a className='email-icon'
                 href="mailto:vitikainenonni@gmail.com">
                    <MailIcon />
                </a>
            </div>
            <Navbar scale={true} section={'contact'}/>
        </div>
    )
}
