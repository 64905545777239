import { useState } from 'react';
import {useNavigate} from 'react-router-dom';

import { MainHeader } from '../../../../Components/Header/MainHeader';
import {ForwardIcon, BackIcon} from '../../../../utils/icons/iconsConfig';

import { Navbar } from '../../../../Components/Navigation/Navbar';

import '../project.css';
import './todolist.css';

// Create a context with all images from the 'fastspot' folder
const imagesContext = require.context(
    '../../../../utils/images/todolist', // Specify the folder path
    false, // Whether to include subdirectories
    /\.(png|jpe?g|svg)$/ // Specify the file extensions
  );
  
  // Get an array of all image paths

  
export const Todolist = () => {
    const navigate = useNavigate();
    const imagePaths = imagesContext.keys().map(imagesContext);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);


    const handleNavigation = (page) => {
        navigate('/projects/' + page)
    }

    const previousImage = () => {
        setCurrentImageIndex((prevIndex) => (imagePaths.length > 1 ? (prevIndex === 0 ? imagePaths.length - 1 : prevIndex - 1) : 0));
    };
    
    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === imagePaths.length - 1 ? 0 : prevIndex + 1));
    };
    
    return (
        <div className="project-page-container todolist-bg">
            <div className="project-container todolist-container-bg">
                <MainHeader text='ONLINE TODOLIST'/>
                <div className='project-navbar'>
                    <button className='todolist'  onClick={() => handleNavigation('fastspots')}>FASTSPOTS</button>
                    <button className='todolist' onClick={() => handleNavigation('todogroups')}>TODO GROUPS</button>
                </div>
                <div className='project-image-technologies'>
                    <div className='project-image'>
                        <button onClick={previousImage}><BackIcon /></button>
                        <img src={imagePaths[currentImageIndex]} alt='Online todolist'></img>
                        <button onClick={nextImage}><ForwardIcon /></button>
                    </div>
                    <div className='project-technologies'>
                        <h2 >TECHNOLOGIES</h2>
                        <ul>
                            <li>Angular</li>
                            <li>MySQL</li>
                            <li>TypeScript</li>
                            <li>Express</li>
                            <li>Node</li>
                        </ul>
                    </div>
                </div>
                <p>An online todolist. Easily accessible over the internet. Goal was to learn using sql database and practice the use of other frontend framework.</p>
                <a className='todolist' href='https://onlinetodolist-z6qc.onrender.com' target='_blank' rel="noreferrer">Try it out</a>
                <Navbar scale={true} section={'projects'} city={true}/>
            </div>
        </div>
    );
}