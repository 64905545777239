import {
  Routes,
  Route,
  Outlet,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";

import "./App.css";
import { Home } from "./Pages/Home/Home";
import { About } from "./Pages/About/About";
import { Projects } from "./Pages/Projects/Projects";
import { Fastspots } from "./Pages/Projects/ProjectPages/Fastspots/Fastspots";
import { Todolist } from "./Pages/Projects/ProjectPages/Todolist/Todolist";
import { TodoGroups } from "./Pages/Projects/ProjectPages/TodoGroups/TodoGroups";
import { Contact } from "./Pages/Contact/Contact";

function App() {
  return (
    <div className="page-container">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/projects/fastspots" element={<Fastspots />} />
        <Route path="/projects/todolist" element={<Todolist />} />
        <Route path="/projects/todogroups" element={<TodoGroups />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    </div>
  );
}
export default App;
