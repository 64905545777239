import "./navbar.css";
import { useNavigate } from "react-router-dom";

export const Navbar = ({
  scale = false,
  section = null,
  city = false,
  todo = false,
  spot = false,
}) => {
  const navigate = useNavigate();

  const handleNavigation = (page) => {
    navigate("/" + page);
  };

  const scaleDown = "scale(0.7)";

  // Function to determine the background color based on props
  const getBackgroundColor = () => {
    if (city) {
      return "var(--city)";
    } else if (todo) {
      return "var(--todo2)";
    } else if (spot) {
      return "var(--spotify)";
    } else {
      return "var(--accent-color)";
    }
  };

  // Function to determine the hover class based on props
  const getHoverClass = () => {
    if (city) {
      return "hover-city";
    } else if (todo) {
      return "hover-todo";
    } else if (spot) {
      return "hover-spot";
    } else {
      return "";
    }
  };

  return (
    <nav>
      <button
        className={`nav-button ${
          section === "about" ? "selected-bg" : ""
        } ${getHoverClass()}`}
        onClick={() => handleNavigation("about")}
        style={{
          backgroundColor: section === "about" ? getBackgroundColor() : "",
          transform: scale ? scaleDown : null,
        }}
      >
        ABOUT
      </button>
      <button
        className={`nav-button ${
          (section === "projects" &&
            (city
              ? "selected-bg-city"
              : todo
              ? "selected-todogroups"
              : spot
              ? "selected-fastspots"
              : "selected-bg")) ||
          ""
        } ${getHoverClass()}`}
        onClick={() => handleNavigation("projects")}
        style={{
          backgroundColor: section === "projects" ? getBackgroundColor() : "",
          transform: scale ? scaleDown : null,
        }}
      >
        PROJECTS
      </button>
      <button
        className={`nav-button ${
          section === "contact" ? "selected-bg" : ""
        } ${getHoverClass()}`}
        onClick={() => handleNavigation("contact")}
        style={{
          backgroundColor: section === "contact" ? getBackgroundColor() : "",
          transform: scale ? scaleDown : null,
        }}
      >
        CONTACT
      </button>
    </nav>
  );
};
