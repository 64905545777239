import { useState } from 'react';
import {useNavigate} from 'react-router-dom';

import { MainHeader } from '../../../../Components/Header/MainHeader';
import {ForwardIcon, BackIcon} from '../../../../utils/icons/iconsConfig';

import { Navbar } from '../../../../Components/Navigation/Navbar';

import '../project.css';
import './fastspots.css';

// Create a context with all images from the 'fastspot' folder
const imagesContext = require.context(
    '../../../../utils/images/fastspot', // Specify the folder path
    false, // Whether to include subdirectories
    /\.(png|jpe?g|svg)$/ // Specify the file extensions
  );
  
  // Get an array of all image paths

  
export const Fastspots = () => {
    const navigate = useNavigate();
    const imagePaths = imagesContext.keys().map(imagesContext);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);


    const handleNavigation = (page) => {
        navigate('/projects/' + page)
    }

    const previousImage = () => {
        setCurrentImageIndex((prevIndex) => (imagePaths.length > 1 ? (prevIndex === 0 ? imagePaths.length - 1 : prevIndex - 1) : 0));
    };
    
    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === imagePaths.length - 1 ? 0 : prevIndex + 1));
    };
    
    return (
        <div className="project-page-container fastspots-bg">
            <div className="project-container fastspots-container-bg">
                <MainHeader text='FASTSPOTS'/>
                <div className='project-navbar'>
                    <button className="fastspot" onClick={() => handleNavigation('todolist')}>ONLINE TODOLIST</button>
                    <button className="fastspot" onClick={() => handleNavigation('todogroups')}>TODO GROUPS</button>
                </div>
                <div className='project-image-technologies'>
                    <div className='project-image'>
                        <button onClick={previousImage}><BackIcon /></button>
                        <img src={imagePaths[currentImageIndex]} alt='Fastspots login page'></img>
                        <button onClick={nextImage}><ForwardIcon /></button>
                    </div>
                    <div className='project-technologies'>
                        <h2 >TECHNOLOGIES</h2>
                        <ul>
                            <li>React</li>
                            <li>Express</li>
                            <li>Redis</li>
                            <li>OAuth</li>
                            <li>Node</li>
                        </ul>
                    </div>
                </div>
                <p>My first larger project.  Uses OAuth to get access to Spotify data and then shows top songs and artists. Makes the playlist creation faster.</p>
                <a className="fastspot" href='https://www.fastspots.net/login' target='_blank' rel="noreferrer">Try it out</a>
                <Navbar scale={true} section={'projects'} spot={true}/>
            </div>
        </div>
    );
}