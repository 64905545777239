import React, { useState } from "react";
import { MainHeader } from "../../Components/Header/MainHeader";
import { Navbar } from "../../Components/Navigation/Navbar";
import "./about.css";
import { Education } from "./Content/Education";
import { Work } from "./Content/Work";
import { Me } from "./Content/Me";

export const About = () => {
  const [selected, setSelected] = useState("education");

  const handleButtonClick = (section) => {
    setSelected(section);
  };

  return (
    <div className="about-container">
      <MainHeader />
      <div className="about-navbar">
        <button
          className={`${selected === "education" ? "selected-color" : ""}`}
          onClick={() => handleButtonClick("education")}
        >
          EDUCATION
        </button>
        <button
          className={`${selected === "work" ? "selected-color" : ""}`}
          onClick={() => handleButtonClick("work")}
        >
          WORK EXPERIENCE
        </button>
        <button
          className={`${selected === "me" ? "selected-color" : ""}`}
          onClick={() => handleButtonClick("me")}
        >
          ABOUT ME
        </button>
      </div>
      <div className="about-content">
        {selected === "education" && <Education />}
        {selected === "work" && <Work />}
        {selected === "me" && <Me />}
      </div>
      <Navbar scale={true} section={"about"} />
    </div>
  );
};
