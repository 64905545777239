import { MainHeader } from "../../Components/Header/MainHeader";
import { Navbar } from "../../Components/Navigation/Navbar";
import "./home.css";

export const Home = () => {
  return (
    <div className="home-container">
      <MainHeader />
      <Navbar />
    </div>
  );
};
