import React from 'react';
import { MainHeader } from '../../Components/Header/MainHeader';
import { Navbar } from '../../Components/Navigation/Navbar';
import {useNavigate} from 'react-router-dom';

import GithubIcon from '../../utils/icons/GithubIcon';

import './projects.css';

export const Projects = () => {
    const navigate = useNavigate();


    const handleNavigation = (page) => {
        navigate('/projects/' + page)
    }

    return(
        <div className='projects-container'>
            <MainHeader/>
            <div className='projects-navbar'>
                <button onClick={() => handleNavigation('fastspots')}>FASTSPOTS</button>
                <button  onClick={() => handleNavigation('todolist')}>ONLINE TODOLIST</button>
                <button onClick={() => handleNavigation('todogroups')}>TODO GROUPS</button>
            </div>
            <div className='projects-content'>
                <p>In my projects I have primarily focused on web application type projects using technologies: React, Redux, Angular, Node, express, SQL.</p>
                <p>These are only some of the projects that have been put online to try out.</p>
                <p>More projects and my academic work can be found on my github.</p>
                <a className='projects-content-button' 
                    href="https://github.com/xmk0987"
                    rel="noreferrer"
                    target="_blank">
                    <GithubIcon />
                </a>
            </div>
            <Navbar scale={true} section={'projects'}/>
        </div>
    )
}
