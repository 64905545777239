import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

const rootElement = document.getElementById("root");

const appRoot = ReactDOM.createRoot(rootElement);

appRoot.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
