import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { MainHeader } from "../../../../Components/Header/MainHeader";
import { ForwardIcon, BackIcon } from "../../../../utils/icons/iconsConfig";

import { Navbar } from "../../../../Components/Navigation/Navbar";

import "../project.css";
import "./todogroups.css";

import Duck from "../../../../utils/images/duck.png";
import Marsu from "../../../../utils/images/marsu.png";

// Create a context with all images from the 'fastspot' folder
const imagesContext = require.context(
  "../../../../utils/images/todogroups", // Specify the folder path
  false, // Whether to include subdirectories
  /\.(png|jpe?g|svg)$/ // Specify the file extensions
);

// Get an array of all image paths

export const TodoGroups = () => {
  const navigate = useNavigate();
  const imagePaths = imagesContext.keys().map(imagesContext);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNavigation = (page) => {
    navigate("/projects/" + page);
  };

  const previousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      imagePaths.length > 1
        ? prevIndex === 0
          ? imagePaths.length - 1
          : prevIndex - 1
        : 0
    );
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === imagePaths.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="project-page-container todogroups-bg">
      <div className="project-container todogroups-container-bg">
        <MainHeader text="TODO GROUPS" />
        <div className="project-navbar">
          <button
            className="todogroups"
            onClick={() => handleNavigation("fastspots")}
          >
            FASTSPOTS
          </button>
          <button
            className="todogroups"
            onClick={() => handleNavigation("todolist")}
          >
            ONLINE TODOLIST
          </button>
        </div>
        <div className="project-image-technologies">
          <div className="project-image">
            <button onClick={previousImage}>
              <BackIcon />
            </button>
            <img src={imagePaths[currentImageIndex]} alt="ToDo Groups"></img>
            <button onClick={nextImage}>
              <ForwardIcon />
            </button>
          </div>
          <div className="project-technologies">
            <h2>TECHNOLOGIES</h2>
            <ul>
              <li>React</li>
              <li>PostgreSQL</li>
              <li>Redux</li>
              <li>AWS</li>
              <li>Express</li>
            </ul>
          </div>
        </div>
        <p>
          A class management system for teachers. Allows users to create groups,
          share todolists and follow the progress in realtime. Reward members,
          buy and customize characters Largest project up to date.{" "}
        </p>
        <a
          className="todogroups"
          href="https://www.todogroups.com/"
          target="_blank"
          rel="noreferrer"
        >
          Try it out
        </a>
        <Navbar scale={true} section={"projects"} todo={true} />
      </div>
      <div className="avatar-container duck">
        <img src={Duck} alt="todogroups mascot"></img>
      </div>
      <div className="avatar-container marsu">
        <img src={Marsu} alt="todogroups mascot"></img>
      </div>
    </div>
  );
};
